import * as React from "react";
import { Link } from "gatsby";

export const Tag = ({ children }: any) => {
  return (
    <Link
      className={"mx-1 no-underline text-white text-xs p-1 bg-gray-400"}
      to={"tags"}
    >
      <span style={{}}>{children}</span>
    </Link>
  );
};
