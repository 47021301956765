import * as React from "react";
import { Link, graphql } from "gatsby";

import Bio from "../components/bio";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { rhythm } from "../utils/typography";
import { Tag } from "../components/Tag";

const pages = (from: number, to: number): number[] => {
  const result = [];
  for (let i = from; i <= to; i++) {
    result.push(i);
  }
  return result;
};

export const BlogPage = (props) => {
  const { data } = props;
  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();
  const posts = data.allMarkdownRemark.edges;
  const pagesNumbers = pages(1, numPages);

  return (
    <Layout>
      <Seo title="Alle Beiträge von Lars" />
      <Bio />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug;
        return (
          <article key={node.fields.slug}>
            <header>
              <h3
                className={"text-blue-500 font-bold text-2xl"}
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <span>
                <small>{node.frontmatter.date}</small>
              </span>
              <span style={{ marginLeft: "16px" }}>
                {node.frontmatter.tags?.map((t) => (
                  <Tag key={t}>{t}</Tag>
                ))}
              </span>
              <span style={{ color: "#d9d9d9", fontSize: "10px" }}>
                | {Math.floor(node.fields.readingTime.minutes)} Minuten Lesezeit
                | {node.fields.readingTime.words} Wörter
              </span>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        );
      })}
      <div className="flex flex-row items-center">
        <span className="text-gray-300 px-2">Seite</span>
        {pagesNumbers.map((page) => {
          return (
            <Link
              to={page === 1 ? "/" : "/blog/" + page}
              style={{ boxShadow: "0 0 0 0" }}
              className={
                "px-3 " +
                (currentPage === page ? nonActiveStyle : " ") +
                (currentPage !== page ? activeStyle : " ")
              }
            >
              {page}
            </Link>
          );
        })}
      </div>
    </Layout>
  );
};

const activeStyle = "text-blue-700 underline";
const nonActiveStyle = "text-white no-underline rounded-full bg-blue-500";

export default BlogPage;

export const blogPageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: $limit
      skip: $skip
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              minutes
              words
              time
              minutes
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`;
